import { PageProperties } from '@/utils/AnalyticsHelper';
import { Meta } from '@/components/Layout/Layout';
import { Review } from '@/components/ui/types';
import { GtmData } from '@/utils/GtmService';

export const STATIC_DIR = '/app/hp-vhp/static/images';

export const HOMEPAY_RX_COOKIE = 'homepayVisitRx';
export const HOMEPAY_PARTNERS_RX_COOKIE = 'hppRx';
export const HOMEPAY_PROMO_CODE_COOKIE = 'hpc';

export const CZEN_VISITOR_COOKIE_KEY = 'n_vis';
export const CZEN_SESSION_COOKIE_KEY = 'csc';
export const CZEN_JSESSIONID_COOKIE_KEY = 'JSESSIONID';
export const LAUNCH_DARKLY_INIT_TIMEOUT = 5000;

export const MAX_ATTEMPTS = 5;

export const PARTNER_NO_BILLING_PLANS = ['BDNoBilling', 'BDNonCoveredServices'];

export const CLIENT_FEATURE_FLAGS: Readonly<Record<string, string>> = Object.freeze({
  PROMO_BANNER: 'homepay-visitor-home-page-promo-banner-enabled',
  TTP_ASSET_URL: 'ttp-asset-url',
  TTP_FAVICON_URL: 'ttp-favicon-url',
  NTC_GATE: 'HPGROW-1588-nanny-tax-calculator-gate',
  PRO_PORTAL_ENABLED: 'HPBRAVO-3008-pro-portal-enabled',
  HOMEPAY_PRICING_TEST_2025: 'HPBRAVO-3250-homepay-pricing-test-2025',
});

export const SERVER_FEATURE_FLAGS = Object.freeze({
  GTM_JS_URL: 'gtm-js-url',
  NTC_DOWNTIME: 'HPBRAVO-2225-vhp-nanny-tax-calculator-downtime',
  PRO_PORTAL_ENABLED: 'HPBRAVO-3008-pro-portal-enabled',
});

export const CARE_TERMS_URL = '/about/terms-of-use/';
export const CONTRACT_RULES_URL = '/c/nanny-contract-rules-to-discuss-after-you-hire-your-nanny/';
export const SAMPLE_CONTRACT_URL = '/media/cms/pdf/nanny-contract-caredotcom.pdf';
export const SAMPLE_SENIOR_CONTRACT_URL =
  '/media/cms/pdf/adult-and-senior-care-contract-caredotcom.pdf';

export const ENROLLMENT_IN_PROGRESS_URI = '/app/hp-enrollment/landing';
export const PARTNER_ENROLLMENT_IN_PROGRESS_URI = '/app/hp-partner-enrollment/taxes';

export const HP_EMAIL = 'info@myhomepay.com';

export const LOGGING_PATH = '/api/logs';

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const reviews: Review[] = [
  {
    id: 1,
    author: 'FABIOLA',
    location: 'WASHINGTON, D.C.',
    comment:
      '"Great services! Keeps track of everything for you and keeps you organized. Very helpful and knowledgeable."',
  },
  {
    id: 2,
    author: 'AMY',
    location: 'AGOURA HILLS, CA',
    comment:
      '"Super helpful and was able to resolve our issue in less than five minutes. The person I spoke with was friendly and service-oriented."',
  },
  {
    id: 3,
    author: 'HILLARY',
    location: 'MECHANICSVILLE, VA',
    comment:
      '"Hands down one of the best customer service departments I\'ve had the pleasure to use. They make everything so effortless and easy! Highly suggest!"',
  },
];

export const PROPORTAL_REVIEWS = {
  title: 'What people are saying about HomePay',
  reviews: [
    {
      id: 1,
      name: 'Marisa B.',
      rating: 5,
      comment:
        'HomePay is awesome! Their entire team is amazing. I own a small accounting firm that does payroll, and they give my team something to aspire to!',
    },
    {
      id: 2,
      name: 'Mark C.',
      rating: 5,
      comment:
        'We have used HomePay for the last 7 years to manage payroll for a nanny who helped us care for our kids as they were growing ... I would highly recommend HomePay to anyone else with people working in their home.',
    },
    {
      id: 3,
      name: 'Sarah T.',
      rating: 5,
      comment:
        "I've had a few nanny tax services but this is the best. I speak to a real person quickly and they always help me out. They make it easy and it is one less thing on my plate!",
    },
  ],
};

export const SERVICES = [
  {
    description:
      "Just provide us with your nanny or caregiver's email and you're done. We'll collect the personal information, W-4 withholding elections, and bank account numbers necessary for payroll.",
    title: 'Hassle-free employee setup',
    url: `${STATIC_DIR}/employee-setup-dt-1x.jpg`,
  },
  {
    description:
      "It's simple to set up automatic payments, and adjusting your nanny's hours and rates is a breeze from any device.",
    title: 'Payroll management made easy',
    url: `${STATIC_DIR}/payroll-dt-1x.jpg`,
  },
  {
    description:
      "Unlike most companies, Care.com HomePay manages state tax notices, so you're in step with your obligations. Notices come directly to us, and we act on your behalf, keeping you updated on everything we do.",
    title: 'Freedom from state tax notices',
    url: `${STATIC_DIR}/freedom-dt-1x.jpg`,
  },
];

export const SERVICE_LIST = [
  {
    items: [
      'Prepare, file and remit federal and state taxes',
      'Point of contact for state tax agencies',
      'Manage official notices',
      'Online access to archived tax records',
      'Set up federal and state tax accounts',
      'File required New Hire Report with tax agencies',
      'Year-end tax preparation, which includes Schedule H, Form 1040, Form W-2, and Form W-3 ($100 yearly fee)',
    ],
    serviceTitle: 'Tax processing',
  },
  {
    items: [
      "Calculate employee's withholdings and net pay",
      'Automatic payroll processing on any device',
      'Direct deposit each pay period',
      'Track PTO accruals and other reimbursements',
      'Online access to payroll records and paystubs',
    ],
    serviceTitle: 'Payroll management',
  },
  {
    items: [
      'Unlimited phone, chat and email support',
      'Unlimited support for your employee(s)',
      "Support if you're ever audited",
      'Lifetime access to your payroll and tax records',
      'On-board your employee(s) for you',
    ],
    serviceTitle: 'Concierge support',
  },
];

export const PHONE_CTA_TEXT = 'Call us for a free consultation ';
export const PHONE_LINK = 'tel:+18882733356';
export const PHONE_LABEL = '(888) 273-3356';

export const TBF_SECTION_SCROLL_CLASS = 'tbf-section-scroll-to';

export interface HireStatusOption {
  text: string;
  value: string;
}

interface HireStatus {
  notYet: HireStatusOption;
  yes: HireStatusOption;
}

// For the URL query param `ss` where 11 means yes and 21 means no
export const HIRE_STATUS: HireStatus = {
  notYet: {
    text: 'Not yet',
    value: '21',
  },
  yes: {
    text: 'Yes',
    value: '11',
  },
};

export const GOOGLE_REVIEWS_URL =
  'https://www.google.com/search?source=hp&ei=dHc5W7CBHMS2sAX31Ke4Cg&q=Care.com+HomePay+Austin&oq=Care.com+HomePay+Austin&gs_l=psy-ab.3..0j38.4085.15242.0.15793.68.23.45.0.0.0.99.1768.23.23.0....0...1.1.64.psy-ab..0.68.1894...0i131k1j0i22i30k1.0.aJKMpY_ndgw';

interface FeaturedBrand {
  dimensions: [width: number, height: number];
  id: string;
  isVisibleOnMobile: boolean;
  name: string;
}

export const FEATURED_BRANDS: FeaturedBrand[] = [
  {
    dimensions: [100, 41],
    id: 'yahoo-finance',
    isVisibleOnMobile: false,
    name: 'Yahoo Finance',
  },
  {
    dimensions: [72, 35],
    id: 'cnn',
    isVisibleOnMobile: true,
    name: 'CNN',
  },
  {
    dimensions: [110, 30],
    id: 'forbes',
    isVisibleOnMobile: true,
    name: 'Forbes',
  },
  {
    dimensions: [180, 41],
    id: 'msn',
    isVisibleOnMobile: false,
    name: 'MSN | Money',
  },
  {
    dimensions: [60, 50],
    id: 'cnbc',
    isVisibleOnMobile: true,
    name: 'CNBC',
  },
  {
    dimensions: [151, 19],
    id: 'huffpost',
    isVisibleOnMobile: false,
    name: 'Huffpost',
  },
  {
    dimensions: [116, 40],
    id: 'us-news',
    isVisibleOnMobile: false,
    name: 'US News',
  },
];

export const NANNY_HELP_FEATURES = [
  'Health insurance',
  'Unemployment insurance',
  'Social security benefits',
  'Home mortgages and car loans',
];

export const HOW_HP_WORKS_VIDEO_URL = 'https://www.youtube.com/embed/CrswHediDIM?autoplay=1';
export const HOW_HP_HELPS_VIDEO_URL = 'https://www.youtube.com/embed/YV5UyrPWI5E?autoplay=1';
export const HOW_HP_WORKS_PARTNERS_URL = 'https://www.youtube.com/embed/2VVRut1PGqU?autoplay=1';
export const HOW_HP_HELPS_LAURA_VIDEO_URL = 'https://www.youtube.com/embed/DTJHMpKmfEg?autoplay=1';
export const HOW_LEGAL_PAYMENTS_HELP_VIDEO_URL =
  'https://www.youtube.com/embed/5RnEW6jXlco?si=8u4Oc-Pk9e5WUNPd';

export const PARTNER_PAGE_TESTIMONIALS = [
  {
    author: 'Christina & Bill, Brookfield, VT',
    body: '"If only everything in life was as simple as HomePay makes taxes and payroll!"',
    id: 1,
    impression: 'Save time and stress',
  },
  {
    author: 'Amy, Agoura Hills, CA',
    body: '"Super helpful and was able to resolve our issue in less than five minutes. The person I spoke with was friendly and service-oriented"',
    id: 2,
    impression: 'Gain unparalleled expertise',
  },
  {
    author: 'Hillary, Mechanisville, VA',
    body: '"Hands down one of the best customer service departments I\'ve had the pleasure to use. They make everything so effortless and easy! Highly suggest!"',
    id: 3,
    impression: 'Enjoy concierge support',
  },
];

export const SOCIAL_MEDIA_REVIEWS = {
  facebook: {
    label: 'Stars on Facebook',
    rating: '4.5',
    url: 'https://www.facebook.com/pg/myhomepay/reviews/',
  },
  google: {
    label: 'Stars on Google',
    rating: '5.0',
    url: 'https://www.google.com/search?source=hp&ei=dHc5W7CBHMS2sAX31Ke4Cg&q=Care.com+HomePay+Austin&oq=Care.com+HomePay+Austin&gs_l=psy-ab.3..0j38.4085.15242.0.15793.68.23.45.0.0.0.99.1768.23.23.0....0...1.1.64.psy-ab..0.68.1894...0i131k1j0i22i30k1.0.aJKMpY_ndgw',
  },
};

export enum StateCodeMap {
  Alabama = 'AL',
  Alaska = 'AK',
  Arizona = 'AZ',
  Arkansas = 'AR',
  California = 'CA',
  Colorado = 'CO',
  Connecticut = 'CT',
  Delaware = 'DE',
  Florida = 'FL',
  Georgia = 'GA',
  Hawaii = 'HI',
  Idaho = 'ID',
  Illinois = 'IL',
  Indiana = 'IN',
  Iowa = 'IA',
  Kansas = 'KS',
  Kentucky = 'KY',
  Louisiana = 'LA',
  Maine = 'ME',
  Maryland = 'MD',
  Massachusetts = 'MA',
  Michigan = 'MI',
  Minnesota = 'MN',
  Mississippi = 'MS',
  Missouri = 'MO',
  Montana = 'MT',
  Nebraska = 'NE',
  Nevada = 'NV',
  'New Hampshire' = 'NH',
  'New Jersey' = 'NJ',
  'New Mexico' = 'NM',
  'New York' = 'NY',
  'North Carolina' = 'NC',
  'North Dakota' = 'ND',
  Ohio = 'OH',
  Oklahoma = 'OK',
  Oregon = 'OR',
  Pennsylvania = 'PA',
  'Rhode Island' = 'RI',
  'South Carolina' = 'SC',
  'South Dakota' = 'SD',
  Tennessee = 'TN',
  Texas = 'TX',
  Utah = 'UT',
  Vermont = 'VT',
  Virginia = 'VA',
  Washington = 'WA',
  'Washington D.C' = 'DC',
  'West Virginia' = 'WV',
  Wisconsin = 'WI',
  Wyoming = 'WY',
}

export const COPYRIGHT = '© 2007-2022 Care.com All rights reserved.';

interface Pages<T> {
  caregiver: T;
  mainVHP: T;
  sampleNannyContract: T;
  nannyTaxCalculator: T;
  partners: T;
  productAndPricing: T;
  seniorCare: T;
  seniorContract: T;
  downtime: T;
  proPortal: T;
}

interface Route {
  relativeURL: string;
}

export const ROUTING_PROPERTIES: Pages<Route> = {
  caregiver: {
    relativeURL: '/caregivers',
  },
  mainVHP: {
    relativeURL: '/',
  },
  nannyTaxCalculator: {
    relativeURL: '/nanny-tax-calculator',
  },
  partners: {
    relativeURL: '/',
  },
  productAndPricing: {
    relativeURL: '/product-and-pricing',
  },
  sampleNannyContract: {
    relativeURL: '/sample-nanny-contract',
  },
  seniorCare: {
    relativeURL: '/senior-care',
  },
  seniorContract: {
    relativeURL: '/sample-senior-care-contract',
  },
  downtime: {
    relativeURL: '/downtime',
  },
  proPortal: {
    relativeURL: '/proportal',
  },
};

export const META_PROPERTIES: Pages<Meta> = {
  caregiver: {
    description:
      'Care.com HomePay is a trusted resource for nannies and caregivers to be paid legally for their services',
    keywords: 'caregiver resource, nanny resource, nanny tax compliance',
    relativeCanonical: '/caregivers',
    title: 'A Resource For Nannies and Caregivers to be Paid Legally | Care.com HomePay',
  },
  mainVHP: {
    description:
      'Care.com HomePay provides award-winning, affordable nanny tax and payroll services for families with household employees',
    keywords:
      'homepay, care.com homepay, nanny payroll service, best nanny payroll service, nanny tax service',
    relativeCanonical: '/',
    title: 'Nanny and Household Tax and Payroll Service | Care.com HomePay',
  },
  sampleNannyContract: {
    description:
      'Care.com HomePay provides award-winning, affordable nanny tax and payroll services for families with household employees',
    keywords: 'sample nanny contract',
    relativeCanonical: '/sample-nanny-contract',
    title: 'sample nanny contract - Care.com HomePay',
  },
  nannyTaxCalculator: {
    description:
      'Use the free Nanny Tax Calculator from Care.com HomePay to see your 2022 nanny payroll, budget and tax breaks.',
    keywords: 'nanny tax, nanny rates, tax calculator, tax benefits',
    relativeCanonical: '/nanny-tax-calculator',
    title: 'Nanny tax and payroll calculator - Care.com HomePay',
  },
  partners: {
    description:
      'HomePay provides award-winning, affordable nanny tax and payroll services for families with household employees',
    keywords: 'homepay, nanny payroll service, nanny tax service',
    title: 'Nanny and Household Tax and Payroll Service | HomePay',
  },
  productAndPricing: {
    description:
      'See how Care.com HomePay’s nanny tax and payroll service simplifies household employment for busy families.',
    keywords:
      'nanny payroll service, best nanny payroll service, nanny tax service, senior care payroll service, senior care tax service',
    relativeCanonical: '/product-and-pricing',
    title: 'Nanny and Household Tax and Payroll Service | Care.com HomePay',
  },
  seniorCare: {
    description:
      'Care.com HomePay provides an award-winning senior care tax and payroll service for families hiring an in-home caregiver.',
    keywords:
      'senior care tax service, senior care payroll service, caregiver payroll taxes, eldercare payroll',
    relativeCanonical: '/senior-care',
    title: 'A Senior Care Tax and Payroll Service for Families',
  },
  seniorContract: {
    description:
      "Use HomePay's 2023 sample senior care contract to keep yourself organized and make the process of hiring a senior caregiver easier.",
    keywords:
      'sample senior care contract, senior care contract, senior care contract template, contract for senior care, senior care employment contract, eldercare agreement',
    relativeCanonical: '/sample-senior-care-contract',
    title: 'Sample Senior Care Contract - Care.com HomePay',
  },
  downtime: {
    description:
      'Care.com HomePay provides award-winning, affordable nanny tax and payroll services for families with household employees',
    keywords:
      'homepay, care.com homepay, nanny payroll service, best nanny payroll service, nanny tax service',
    relativeCanonical: '/downtime',
    title: 'Nanny and Household Tax and Payroll Service | Care.com HomePay',
  },
  proPortal: {
    description:
      'HomePay ProPortal provides a dashboard built for professional advisors backed by our team of experts in household payroll, tax and labor law.',
    keywords:
      'homepay, proportal, homepay, nanny payroll service, best nanny payroll service, nanny tax service',
    relativeCanonical: '/proportal',
    title: 'HomePay ProPortal Household Tax and Payroll Dashboard',
  },
};

/**
 * The values here are set to match the current CZEN deployment in order to keep
 * Amplitude analytics consistent even after the switch to this MFE.
 *
 * Some values may not make much sense (i.e. partners page not having
 * seo_page_id), but it's vital that we don't mess up Product's amplitude
 * tracking dashboards by using their current values
 */
export const PAGE_PROPERTIES: Pages<PageProperties> = {
  caregiver: {
    mfe_page_id: "I'm a Caregiver",
    screen_name: "HP-VHP-MFE - I'm a Caregiver",
    screen_template: 'page.homepay.homepayCaregivers',
    url_path: '/homepay/caregivers',
  },
  mainVHP: {
    mfe_page_id: 'Main VHP',
    screen_name: 'HP-VHP-MFE - Main VHP',
    screen_template: 'page.homepayMinisite.homepayRedesign',
    seo_page_id: 1424,
    url_path: '/homepay',
  },
  sampleNannyContract: {
    mfe_page_id: 'Sample Nanny Contract',
    screen_name: 'HP-VHP-MFE - Sample Nanny Contract',
    screen_template: 'page.homepay.sampleNannyContract',
    url_path: '/homepay/sample-nanny-contract',
  },
  nannyTaxCalculator: {
    mfe_page_id: 'Nanny Tax Calculator',
    screen_name: 'HP-VHP-MFE - Nanny Tax Calculator',
    screen_template: 'page.homepay.homepaySeekerCalculatorMinisiteGated',
    seo_page_id: 1422,
    url_path: '/homepay/nanny-tax-calculator',
  },
  partners: {
    mfe_page_id: 'Partners',
    screen_name: 'HP-VHP-MFE - Partners',
    screen_template: 'page.homepayMinisite.homepayPartners',
    url_path: '/',
  },
  productAndPricing: {
    mfe_page_id: 'Product and Pricing',
    screen_name: 'HP-VHP-MFE - Product and Pricing',
    screen_template: 'page.homepayMinisite.homepayMinisiteServiceAndPricing',
    seo_page_id: 1472,
    url_path: '/homepay/product-and-pricing',
  },
  seniorCare: {
    mfe_page_id: 'Senior Care',
    screen_name: 'HP-VHP-MFE - Senior Care',
    screen_template: 'page.homepay.homepaySeniorCare',
    seo_page_id: 1483,
    url_path: '/homepay/senior-care',
  },
  seniorContract: {
    mfe_page_id: 'Sample Senior Care Contract',
    screen_name: 'HP-VHP-MFE - Sample Senior Care Contract',
    screen_template: 'page.homepay.homePaySampleSeniorCareContract',
    url_path: '/homepay/sample-senior-care-contract',
  },
  downtime: {
    mfe_page_id: 'Downtime',
    screen_name: 'HP-VHP-MFE - Downtime',
    screen_template: 'page.homepay.homepayDowntime',
    url_path: '/homepay/downtime',
  },
  proPortal: {
    mfe_page_id: 'ProPortal',
    screen_name: 'HP-VHP-MFE - ProPortal',
    screen_template: '',
    url_path: '/homepay/proportal',
  },
};

export const GTM_DATA: Pages<GtmData> & { nonMember: GtmData } = {
  caregiver: {
    slots: [],
  },
  mainVHP: {
    slots: ['/us-homepay/segment/nonmember/viewed-vhp/'],
  },
  nonMember: {
    slots: ['/us-subscription/segment/nonmember/'],
  },
  sampleNannyContract: {
    slots: ['/us-homepay/segment/nonmember/generated-lead'],
  },
  nannyTaxCalculator: {
    slots: ['/us-homepay/segment/nonmember/nanny-tax-calculator-lead'],
  },
  partners: {
    slots: [],
  },
  productAndPricing: {
    slots: [],
  },
  seniorCare: {
    slots: [],
  },
  seniorContract: {
    slots: ['/us-homepay/segment/nonmember/generated-sc-lead'],
  },
  downtime: {
    slots: [],
  },
  proPortal: {
    slots: [],
  },
};
